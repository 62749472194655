import { Directive, HostListener } from '@angular/core';
import CustomBottomSheet from '@services/custom-bottom-sheet.service';

@Directive({
  selector: '[appCloseCustomBottomSheet]',
  standalone: true,
})
export class CloseCustomBottomSheetDirective {
  constructor(private readonly customBottomSheet: CustomBottomSheet) {}

  @HostListener('click')
  close(): void {
    this.customBottomSheet.dismiss();
  }
}
